import * as React from 'react'
import { graphql, Link, StaticQuery } from 'gatsby'
import { Language } from '../../state/index'

interface MediaQuery {
  _cafeteira_min: { publicURL: string }
  _phillipe: { publicURL: string }
}

const HomeProcess: React.SFC = () => (
  <Language.Consumer>
    {language => (
      <StaticQuery
        render={(data: MediaQuery) => (
          <section id="process">
            <div className="title">
              <h1>{language.translate('PROCESS_TITLE')}</h1>
              <h3>{language.translate('PROCESS_SUBTITLE')}</h3>
            </div>
            <div className="action">
              <Link to={'/process'} className="button-yellow">
                {language.translate('PROCESS_ACTION')}
              </Link>
            </div>
            <div className="row">
              <img className="cafeteira" src={data._cafeteira_min.publicURL} />
              <img className="phillipe" src={data._phillipe.publicURL} />
            </div>
          </section>
        )}
        query={graphql`
          {
            _cafeteira_min: file(relativePath: { eq: "media/cafeteira-min.svg" }) {
              publicURL
            }
            _phillipe: file(relativePath: { eq: "media/phillipe.gif" }) {
              publicURL
            }
          }
        `}
      />
    )}
  </Language.Consumer>
)

export default HomeProcess
