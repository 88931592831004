import * as React from 'react'

import Page from '../components/Page'
import IndexLayout from '../layouts'
import HomeIntro from '../components/home/intro'
import HomePortfolio from '../components/home/portfolio'
import HomeProcess from '../components/home/process'
import ContactForm from '../components/contact/section'

const IndexPage: React.StatelessComponent = () => (
  <IndexLayout>
    <Page>
      <HomeIntro />
      <HomePortfolio />
      <HomeProcess />
      <ContactForm />
    </Page>
  </IndexLayout>
)

export default IndexPage
