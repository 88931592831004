import * as React from 'react'
import { graphql, Link, StaticQuery } from 'gatsby'

import { Language } from '../../state/index'
import { PageLanguageFlags } from '../LanguageFlags'

interface MediaQuery {
  _7bits: { publicURL: string }
  _7bits_mobile: { publicURL: string }
  _daft_punk: { publicURL: string }
  _gabriel: { publicURL: string }
}

type HomeIntroInjectedProps = MediaQuery

const HomeIntro: React.SFC = () => (
  <StaticQuery
    render={(data: HomeIntroInjectedProps) => (
      <Language.Consumer>
        {language => (
          <section id="intro">
            <div className="topbar">
              <img className="logo logo-desktop" src={data._7bits.publicURL} />
              <img className="logo logo-mobile" src={data._7bits_mobile.publicURL} />

              <div className="action" style={{ display: 'flex', alignItems: 'center' }}>
                <PageLanguageFlags />
                <Link to="/blog/" className="button-blue">
                  {language.translate('BLOG_ACTION')}
                </Link>
              </div>
            </div>
            <img className="daft-punk" src={data._daft_punk.publicURL} />
            <img className="gabriel" src={data._gabriel.publicURL} />
          </section>
        )}
      </Language.Consumer>
    )}
    query={graphql`
      {
        _7bits: file(relativePath: { eq: "media/7bits-min.svg" }) {
          publicURL
        }
        _7bits_mobile: file(relativePath: { eq: "media/7bits-mobile.svg" }) {
          publicURL
        }
        _daft_punk: file(relativePath: { eq: "media/daft-punk-min.svg" }) {
          publicURL
        }
        _gabriel: file(relativePath: { eq: "media/gabriel.gif" }) {
          publicURL
        }
      }
    `}
  />
)

export default HomeIntro
