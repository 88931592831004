import * as React from 'react'
import { chunk } from 'lodash'
import { Link } from 'gatsby'
import { BaseEntity } from '../Entities'

export class Tile<T extends BaseEntity> extends React.Component<{
  entity: T
  big?: boolean
  dir: string
  className?: string
}> {
  render() {
    const { entity, dir, className, big = false } = this.props
    return (
      <Link to={`/${dir}/${entity.uid}`} className={`${className || ''} ${big ? 'big' : ''}`}>
        <div
          className="image"
          style={{ backgroundImage: `url('${entity.data.cover_picture.url}')` }}
        />
        <h2>{entity.data.title.text}</h2>
        <p>{entity.data.subtitle.text}</p>
      </Link>
    )
  }
}

type BaseEntityEdge<T extends BaseEntity> = { node: T }[]

type TileListProps<T extends BaseEntity> = {
  dir: string
  page: number
  pageLength: number
  entities: BaseEntityEdge<T>
  tileClassName?: string
}

export const TileList: React.SFC<TileListProps<BaseEntity>> = ({
  page,
  pageLength,
  entities,
  dir,
  tileClassName
}) => (
  <div className="projects">
    {chunk(entities.slice(pageLength * page, pageLength * (page + 1)), 2).map((list, i) => (
      <div className="row" key={i}>
        {list.map((item, j) => (
          <Tile
            key={j}
            dir={dir}
            className={tileClassName}
            entity={item.node}
            big={(j + i) % 2 === 0}
          />
        ))}
      </div>
    ))}
  </div>
)
