import * as React from 'react'
import { graphql, Link, StaticQuery } from 'gatsby'
import { Language } from '../../state/index'
import { Tile } from '../tile'
import { ProjectTile, FilterLanguage } from '../../Entities'

interface PortfolioQuery {
  portfolio: {
    edges: {
      node: ProjectTile
    }[]
  }
}

const HomePortfolio: React.SFC = () => (
  <Language.Consumer>
    {language => (
      <StaticQuery
        render={(data: PortfolioQuery) => (
          <section id="portfolio">
            <div className="title">
              <h1>{language.translate('PORTFOLIO_TITLE')}</h1>
              <h3>{language.translate('PORTFOLIO_SUBTITLE')}</h3>
            </div>
            <div className="projects">
              {FilterLanguage(language.selected, data.portfolio.edges)
                .slice(0, 3)
                .map(project => (
                  <Tile
                    dir="project"
                    className="project"
                    key={project.node.uid}
                    entity={project.node}
                  />
                ))}
            </div>
            <div className="action">
              <Link to={'/portfolio'} className="button-purple">
                {language.translate('PORTFOLIO_ACTION')}
              </Link>
            </div>
          </section>
        )}
        query={graphql`
          {
            portfolio: allPrismicProject(sort: { fields: first_publication_date, order: DESC }) {
              edges {
                node {
                  uid
                  first_publication_date
                  last_publication_date
                  lang
                  data {
                    link
                    title {
                      text
                    }
                    subtitle {
                      text
                    }
                    cover_picture {
                      url
                      alt
                    }
                  }
                }
              }
            }
          }
        `}
      />
    )}
  </Language.Consumer>
)

export default HomePortfolio
