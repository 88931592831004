import * as React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import { Language } from '../../state/index'

class ContactForm extends React.Component<{}> {
  state = {
    canSubmit: false
  }

  form = React.createRef<HTMLFormElement>()
  phone = React.createRef<HTMLInputElement>()

  validate = () => {
    const formRef = this.form.current
    const phoneRef = this.phone.current
    const formValid = formRef ? formRef.checkValidity() : false
    const phoneValid = phoneRef
      ? phoneRef.value && phoneRef.value.replace(/[^\d\+]/g, '').length > 8
      : false

    this.setState({
      canSubmit: formValid && phoneValid
    })
  }

  render() {
    const { canSubmit } = this.state
    return (
      <Language.Consumer>
        {language => (
          <StaticQuery
            render={data => (
              <section id="contact">
                <div className="title">
                  <h1>{language.translate('CONTACT_TITLE')}</h1>
                  <h3>{language.translate('CONTACT_SUBTITLE')}</h3>
                </div>
                <form
                  ref={this.form}
                  id="contact-form"
                  name="contact-form"
                  data-netlify="true"
                  action="/contact_form_success"
                >
                  <input type="hidden" name="form-name" value="contact-form" />

                  <input
                    type="text"
                    name="name"
                    placeholder={language.translate('CONTACT_FORM_NAME')}
                    required
                    onKeyUp={this.validate}
                  />

                  <input
                    type="email"
                    name="email"
                    placeholder={language.translate('CONTACT_FORM_EMAIL')}
                    required
                    onKeyUp={this.validate}
                  />

                  <input
                    ref={this.phone}
                    type="phone"
                    name="phone"
                    placeholder={language.translate('CONTACT_FORM_PHONE')}
                    required
                    onKeyUp={this.validate}
                  />

                  <textarea
                    placeholder={language.translate('CONTACT_FORM_MESSAGE')}
                    name="message"
                    rows={3}
                    required
                    onKeyUp={this.validate}
                  />

                  <div className="action">
                    <input
                      type="submit"
                      className={`button-salmon ${canSubmit ? '' : 'disabled'}`}
                      form="contact-form"
                      disabled={!canSubmit}
                      value={language.translate('ACTION_SEND')}
                    />
                  </div>
                </form>
                <img className="relogio" src={data.relogio.publicURL} />
                <img className="contato" src={data.contato.publicURL} />
              </section>
            )}
            query={graphql`
              {
                relogio: file(relativePath: { eq: "media/relogio-min.svg" }) {
                  publicURL
                }
                contato: file(relativePath: { eq: "media/contato.gif" }) {
                  publicURL
                }
              }
            `}
          />
        )}
      </Language.Consumer>
    )
  }
}

export default ContactForm
